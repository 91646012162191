import React from "react";
import { useState } from "react";
import "./portal.css";
import Welcome from "./components/welcome/welcome";
import TherapistInfo from "./components/therapist_info/therapist_info";
import Selector from "./components/selector/selector";
import PatientCrud from "./components/patient_crud/patient_crud";
import TherapistsCrud from "./components/therapists_crud/therapists_crud";
import Meetings from "./components/meetings/meetings";
import Paysheet from "./components/paysheet/paysheet";
import Billing from "./components/billing/billing";
import News from "./components/news/news";
import Tasks from "./components/tasks/tasks";
import Forms from "./components/forms/forms";
import Ordenes from "./components/ordenes/ordenes"

function Portal(props) {
  const [selection, setSelection] = useState("welcome");
  const GetSelection = (data) => {
    setSelection(data);
  };

  return (
    <div className="main_container">
      <div className="render_selector">
        <Selector selector={GetSelection} />
      </div>
      <div className="content_container">
        <Importador seleccion={selection} />
      </div>
    </div>
  );
}

const Importador = (props) => {
  switch (props.seleccion) {
    case "welcome":
      return <Welcome />;
      break;
    case "therapist_info":
      return <TherapistInfo />;
      break;
    case "patient_crud":
      return <PatientCrud />;
      break;
    case "therapists_crud":
      return <TherapistsCrud />;
      break;
    case "meetings":
      return <Meetings />;
      break;
    case "paysheet":
      return <Paysheet />;
      break;
    case "billing":
      return <Billing />;
      break;
    case "news":
      return <News />;
      break;
    case "tasks":
      return <Tasks />;
      break;
    case "forms":
      return <Forms />;
      break;
    case "ordenes":
      return <Ordenes/>;
      break;
  }
};
export default Portal;
