import React from "react";
import "./selector.css";
import Cookies from "universal-cookie";

const Selector = (props) => {
  const cookies = new Cookies();

  const Selectionate = (value) => {
    props.selector(value);
  };

  const LogOut = () => {
    window.location.href = "./";
    cookies.remove("user", { path: "/" });
    cookies.remove("name", { path: "/" });
    cookies.remove("perms", { path: "/" });
  };

  if (cookies.get("perms") === "admin") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("patient_crud");
          }}
        >
          Consultar pacientes
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("therapists_crud");
          }}
        >
          Consultar terapeutas
        </button>

        <button
          className="select_button"
          onClick={() => {
            Selectionate("meetings");
          }}
        >
          Consultar autorizaciones
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("therapist_info");
          }}
        >
          Seguimiento firmas
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("billing");
          }}
        >
          Facturación
        </button>
        <button className="select_button" onClick={() => Selectionate("news")}>
          Novedades
        </button>
        <button className="select_button" onClick={() => Selectionate("tasks")}>
          Pendientes
        </button>
        <button
          className="select_button"
          onClick={() => Selectionate("ordenes")}
        >
          Ordenes
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  } else if (cookies.get("perms") === "office") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  } else if (cookies.get("perms") === "therapist") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("meetings");
          }}
        >
          Consultar autorizaciones
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("therapist_info");
          }}
        >
          Seguimiento firmas
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("forms");
          }}
        >
          Solicitudes
        </button>
        <button className="select_button" onClick={() => Selectionate("news")}>
          Reportar novedad
        </button>
        <button className="select_button" onClick={() => Selectionate("tasks")}>
          Pendientes
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  } else if (cookies.get("perms") === "supervisor") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("meetings");
          }}
        >
          Consultar autorizaciones
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("therapist_info");
          }}
        >
          Seguimiento firmas
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("forms");
          }}
        >
          Solicitudes
        </button>
        <button className="select_button" onClick={() => Selectionate("news")}>
          Reportar novedad
        </button>
        <button className="select_button" onClick={() => Selectionate("tasks")}>
          Pendientes
        </button>
        <button
          className="select_button"
          onClick={() => Selectionate("ordenes")}
        >
          Ordenes
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  } else if (cookies.get("perms") === "inventory") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  } else if (cookies.get("perms") === "finance") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("therapist_info");
          }}
        >
          Seguimiento firmas
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("billing");
          }}
        >
          Facturación
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  } else if (cookies.get("perms") === "news") {
    return (
      <>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("welcome");
          }}
        >
          Inicio
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("meetings");
          }}
        >
          Consultar autorizaciones
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("paysheet");
          }}
        >
          Nomina
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("therapist_info");
          }}
        >
          Seguimiento firmas
        </button>
        <button
          className="select_button"
          onClick={() => {
            Selectionate("news");
          }}
        >
          Novedades
        </button>
        <button className="select_button" onClick={() => Selectionate("tasks")}>
          Pendientes
        </button>
        <button className="select_button" onClick={LogOut}>
          Cerrar sesion
        </button>
      </>
    );
  }
};

export default Selector;
