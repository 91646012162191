import React, { useState, useEffect } from "react";
import axios from "axios";

const Ordenes = () => {
  const [data, setData] = useState([]);
  const [opcion, setOpcion] = useState("Domicilios");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/server/ordenes.php",
        {
          params: { Opcion: opcion },
        }
      );
      setData(res.data.reverse());
    } catch (err) {
      console.log(err);
    }
  };

  const Next = () => {
    if (end > data.length) {
      alert("No hay mas registros que mostrar");
    } else {
      setStart(start + 20);
      setEnd(end + 20);
    }
  };

  const Previous = () => {
    if (start === 0) {
      alert("No hay datos anteriores para mostrar");
    } else {
      setStart(start - 20);
      setEnd(end - 20);
    }
  };

  useEffect(() => {
    fetchallData();
    console.log(data);
  }, [opcion]);

  return (
    <>
      <select onChange={(e) => setOpcion(e.target.value)}>
        <option hidden>Seleccione la tabla a consultar</option>
        <option>Sede</option>
        <option>Domicilios</option>
      </select>
      <div className="buttons">
        <button onClick={Previous}>Anterior</button>
        <button onClick={Next}>Siguiente</button>
      </div>

      <table className="tab_content">
        <thead>
          <tr>
            <th>Nombre del paciente</th>
            <th>Documento del paciente</th>
            <th>Nombre del responsable</th>
            <th>Documento del responsable</th>
            <th>Servicios</th>
            <th>Proveedor</th>
            <th>Horario</th>
            <th>Link Orden</th>
            <th>Link Consentimiento</th>
            <th>Fecha Registro</th>
            <th>Celular</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(start, end).map((patient, key) => (
            <tr key={key}>
              <td className="table-data">{patient.NombrePaciente}</td>
              <td className="table-data">{patient.DocumentoPaciente}</td>
              <td className="table-data">{patient.NombreResponsable}</td>
              <td className="table-data">{patient.DocumentoResponsable}</td>
              <td className="table-data">{patient.Servicios}</td>
              <td className="table-data">{patient.Proveedor}</td>
              <td className="table-data">{patient.Horario}</td>
              <td className="table-data">{patient.LinkOrden}</td>
              <td className="table-data">{patient.LinkConsentimiento}</td>
              <td className="table-data">{patient.FechaRegistro}</td>
              <td className="table-data">{patient.Celular}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Ordenes;
